import React, { useCallback, useEffect, useState } from 'react';

import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';

import TabNavigator from './navigators/TabNavigator';

import { isEmpty } from '/util';

import { useLinkTo } from '@react-navigation/native';
import { Platform } from 'react-native';
import LoginStack from './navigators/LoginStack';
import { useAuthContext } from '/context';
import TakeSurveyScreen from '/screens/TakeSurveyScreen';

import * as Linking from 'expo-linking';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import MigrationPreAnnouncementBanner from '/components/MigrationPreAnnouncementBanner';
import AsyncStorage from '@react-native-async-storage/async-storage';

const OnboardStackComponent = React.lazy(
  () => import('./navigators/OnboardStack'),
);
const OnboardStack = () => {
  return (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <OnboardStackComponent />
    </React.Suspense>
  );
};

const RootStack = createStackNavigator();

const AppNavigator = () => {
  const {
    userAttributes,
    userData,
    isAuthenticating,
    fetching,
    fetchUserError,
  } = useAuthContext();

  const [hasSeenMigrationPreAnnouncement, setHasSeenMigrationPreAnnouncement] =
    useState(true);

  useEffect(() => {
    const checkIfHasSeenMigrationPreAnnouncement = async () => {
      const hasSeen = await AsyncStorage.getItem(
        'has-seen-migration-pre-announcement',
      );
      setHasSeenMigrationPreAnnouncement(!!hasSeen);
    };
    checkIfHasSeenMigrationPreAnnouncement();
  }, []);

  const linkTo = useLinkTo();

  const linkingUrl = Linking.useURL();

  /** iOS and Android ONLY */
  const handleLinkingUrl: Linking.URLListener = useCallback(
    ({ url }) => {
      try {
        const { hostname, path, queryParams, scheme } = Linking.parse(url);

        const isCustomScheme = scheme === 'keyconservation';
        const hasQueryParams = !isEmpty(queryParams);

        // If this is a deep link that uses the keyconservation:// scheme,
        // then the `hostname` will actually be part of the path
        let fullPath = `${
          isCustomScheme && hostname?.trim().length ? '/' + hostname : ''
        }${path?.trim().length ? '/' + path : ''}`;

        let queryParamsString = hasQueryParams
          ? url.slice(url.indexOf('?'))
          : '';

        linkTo(fullPath + queryParamsString);
      } catch (error) {
        console.warn('Error handling linking url', error);
      }
    },
    [linkTo],
  );

  useEffect(() => {
    /** Web linking is automatically handled by React Navigation on page load */
    if (!linkingUrl || Platform.OS === 'web') return;

    handleLinkingUrl({ url: linkingUrl });
  }, [handleLinkingUrl, linkingUrl]);

  const sub = userAttributes?.sub;
  const onboarded = userData?.onboarded;

  // useful debug:
  // console.log(
  //   'AppNavigator',
  //   sub,
  //   onboarded,
  //   isAuthenticating,
  //   fetching,
  //   fetchUserError,
  // );

  const shouldHideTabs = !sub || (!!sub && fetchUserError && !onboarded);

  return (
    <>
      {hasSeenMigrationPreAnnouncement ? null : (
        <MigrationPreAnnouncementBanner
          onDismiss={() => setHasSeenMigrationPreAnnouncement(true)}
        />
      )}
      <RootStack.Navigator
        screenOptions={{
          title: 'Key Conservation',
          headerShown: false,
        }}
      >
        {/* Conditionally replace TabNavigator stack with Onboard stack if authenticated but not onboarded */}
        {sub &&
        !onboarded &&
        !isAuthenticating &&
        !fetching &&
        !fetchUserError ? (
          <RootStack.Screen
            options={{
              headerShown: false,
            }}
            name="Onboard"
            component={OnboardStack}
          />
        ) : // If running natively, require user to log in before accessing content
        shouldHideTabs && Platform.OS !== 'web' ? null : (
          <RootStack.Screen name="tabs" component={TabNavigator} />
        )}
        {/* Conditionally include auth stack if not authenticated (Except on web)  */}
        {((!!sub &&
          !!userAttributes?.email_verified &&
          !(isAuthenticating || fetching || fetchUserError)) ||
          !shouldHideTabs) &&
        Platform.OS !== 'web' ? null : (
          <RootStack.Screen
            options={() => ({
              title: 'Sign In',
              headerShown: false,
            })}
            name="auth"
            component={LoginStack}
          />
        )}

        {/* Shared modal screens */}
        <RootStack.Screen
          component={TakeSurveyScreen}
          name="TakeSurvey"
          options={TakeSurveyScreenOptions}
        />
      </RootStack.Navigator>
    </>
  );
};

const TakeSurveyScreenOptions = (): StackNavigationOptions => ({
  headerTitle: 'SURVEY',
  presentation: 'modal',
});

export default AppNavigator;
